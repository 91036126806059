import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import ArrowIcon from "../../../elements/V2/Icons/ArrowIcon"
import Typography from "../../../elements/V2/Typography"

type Props = {
  id: string
  icon: Storyblok.Asset
  text: string
  dropdownText: string
  dropdownLinkText: string
  dropdownLinkUrl: string
  mode: "light" | "dark"
}

// Note: this is meant to work as pure html and css using react-dom renderToString
const InteractiveParagraphDropdown = ({
  id,
  icon,
  text,
  dropdownText,
  dropdownLinkText,
  dropdownLinkUrl,
  mode,
}: Props) => {
  const checkbox_id = `interactive-paragraph-dropdown-${id}`

  return (
    <span
      className={classNames(
        "group/interactive-paragraph relative z-10 inline-block align-middle transition-colors duration-300 ease-in-out focus:z-20 has-[:checked]:z-20 has-[:checked]:text-white md:hover:text-white",
        mode === "light" ? "text-black" : "text-blue"
      )}
    >
      {/* Hidden checkbox to control toggle state of dropdown */}
      <input type="checkbox" className="absolute hidden" id={checkbox_id} />

      <label
        htmlFor={checkbox_id}
        className="relative z-10 flex cursor-pointer items-center px-16 py-8 !leading-none lg:px-20 lg:py-16"
      >
        <img
          src={icon.filename}
          alt={icon.alt || "paragraph icon"}
          width={24}
          height={24}
          className="mr-8 h-24 w-24 opacity-100 transition-all duration-300 ease-in-out group-has-[:checked]/interactive-paragraph:mr-0 group-has-[:checked]/interactive-paragraph:w-0 group-has-[:checked]/interactive-paragraph:opacity-0"
        />

        {text}

        <img
          src={"/icons/V2/icon-close-white.svg"}
          width={24}
          height={24}
          alt="Close icon"
          className="ml-0 h-24 max-w-0 transition-all duration-300 ease-in-out group-has-[:checked]/interactive-paragraph:ml-8 group-has-[:checked]/interactive-paragraph:max-w-24"
        />
      </label>

      <div
        className={classNames(
          "rounded-lg py-2xs absolute left-0 top-0 flex max-h-40 w-full flex-col overflow-hidden px-16 pb-16 transition-all duration-300 ease-in-out group-has-[:checked]/interactive-paragraph:max-h-[300px] md:pb-24 lg:max-h-60 lg:px-20",
          mode === "light"
            ? "bg-light-blue group-has-[:checked]/interactive-paragraph:bg-charcoal md:group-hover/interactive-paragraph:bg-charcoal"
            : "bg-charcoal group-has-[:checked]/interactive-paragraph:bg-blue md:group-hover/interactive-paragraph:bg-blue"
        )}
      >
        <Typography
          text={dropdownText}
          font="grotesk"
          size="body-lg"
          weight="book"
          className="mt-40 lg:mt-56"
          color={Color.White}
        />

        <TextButton
          color={mode === "light" ? Color.Blue : Color.White}
          style="icon"
          className="mt-xs"
          text={dropdownLinkText}
          linkUrl={dropdownLinkUrl}
          icon={<ArrowIcon />}
        />
      </div>
    </span>
  )
}

export default InteractiveParagraphDropdown
